import React from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Breadcrumbs from "../components/ui/breadcrumbs"

import { testimonialsData } from "../data/testimonialsData"
import icon from "../../static/icons/quote.svg"

const Item = ({ testimonial, auth }) => {
    return (
        <div className="item">
            <img className="icon" src={icon} alt="quote icon" />
            <i className="text">"{testimonial}"</i>
            <p className="author">– {auth}</p>
        </div>
    )
}

const TestimonialsPage = (props) => {
    return (
        <Layout location={props.location}>
            <Seo title="Testimonials" />
            <div className="container">
                <Breadcrumbs loc="testimonials" />
                <h1>What they say about us</h1>
            </div>

            <div className="container testimonials">
                {testimonialsData.map(({ text, author }) => (
                    <Item auth={author} testimonial={text} key={author} />
                ))}
            </div>
        </Layout>
    )
}

export default TestimonialsPage
